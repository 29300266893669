import { Checkbox, ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core'
import { 
	Column, 
	Container,
	ContentInfo,
	EmptyContent,
	EmptyLabel,
	EmptyTable,
	InputContent, 	
	InputSearch, 
	MenuProps,	
	ModalContainer,
	OnEditButton,
	OnGenerateCSVButton, 
	OptionDate,
	PaginationButton,
	Row,
	SearchPeriodContent,
	SelectDate, 
	THeadRow, 
	Table,
	TableBody,
	TableCell,
	TableCellFixed,
	TableHeader,
	TableHeaderContent,
	TableHeaderFixed,
	TableRow,
	Title,
} from './styles'
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowDown, faArrowUp, faFileCsv, faFilePdf, faInbox, faQrcode} from '@fortawesome/free-solid-svg-icons'
import { Button } from 'src/styles/globalStyles'
import { CSVLink } from "react-csv"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { PURPLE } from 'src/styles/colors'
import React from 'react'
import { RowCalendarDateHour } from '../RowCalendarDateHour'
import { debounce } from 'lodash'
import dictionary from 'src/utils/dictionary'
import { isUndefinedOrNull} from 'src/genericFunctions'

const TableReport = ({rows, columns, name, pagination, filters, loading, 
	selectable, onEdit, onDelete, onEditTitle, onDeleteTitle, onGenerateCSV, 
	onGeneratePDF, onSelect, onSelectAll, selectAll, selectedIds, deselectIds,
	onChangeView, view, registerTitle, onGenerateQRCODE, actions, showHiddenColumn,
	fromYears, hideSearchPeriod
}) => {
	const [originalRows, setOriginalRows] = React.useState([])
	const [filterDate, setFilterDate] = React.useState({ startDate: filters?.dateFilter?.initialDate, endDate: filters?.dateFilter?.endDate })
	const [headers, setHeaders] = React.useState([])
	const [selectableRows, setSelectableRows] = React.useState([])
	const [orderColumn, setOrderColumn] = React.useState({column: null, order: null, orderType: 'string', orderServer: false })
	const [allColumns, setAllColumns] = React.useState([])
	let headerLeft = 0
	const [totalSelected, setTotalSelected] = React.useState(0)	
	const [years, setYears] = React.useState([])
	const [year, setYear] = React.useState('')

	const tableRef = React.createRef(null)	

	React.useEffect(() => {
		if (orderColumn.column && orderColumn.order && !orderColumn.orderServer) {
			let sortArray = [].concat(rows).sort((a, b) => {
				let field_a = a[orderColumn.column] ? a[orderColumn.column] : ''
				let field_b = b[orderColumn.column] ? b[orderColumn.column] : ''

				if (orderColumn.orderType === 'date') 
					return orderColumn.order === 'ASC' 
					? new Date(field_a) - new Date(field_b)
					: new Date(field_b) - new Date(field_a)
				
				if (orderColumn.orderType === 'number') {
					if(field_a.length === 0)
						field_a = 0
					
					if(field_b.length === 0)
						field_b = 0

					return orderColumn.order === 'ASC'
					? parseInt(field_a) - parseInt(field_b)
					: parseInt(field_b) - parseInt(field_a)
				}

				return orderColumn.order === 'ASC' 
					? field_a.toString().localeCompare(field_b)
					: field_b.toString().localeCompare(field_a)
			})
			setOriginalRows(sortArray)
		} else {
			setOriginalRows(rows)
		}

		if(selectAll)
			setSelectableRows(rows)

		if(selectAll && deselectIds?.length > 0)
			setTotalSelected(pagination.total - deselectIds.length)
		else if (!selectAll && selectedIds?.length > 0)
			setTotalSelected(selectedIds.length)
		else if (selectAll)
			setTotalSelected(pagination.total)
		else
			setTotalSelected(0)

		let headersMapper = []
		if(Array.isArray(columns) && columns.length > 0) {
			for (let i = 0; i < columns.length; i++) {
				headersMapper.push({
					label: columns[i].csvHeader,
					key: columns[i].accessor,
				})
			}
			setHeaders(headersMapper)
		}
		
		let currentYear = new Date().getFullYear()
		
		let countYears = (fromYears && typeof fromYears === 'number') 
			? fromYears : currentYear

		let mapYears = []

		for (let i = 1980; i <= countYears; i++) {
			mapYears.push(i)
		}

		setYears(mapYears)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deselectIds, orderColumn, pagination.total, rows, selectAll, selectedIds, fromYears])
	
	React.useEffect(() => {		
		setAllColumns(columns)
	}, [columns])

	React.useEffect(() => {		
		tableRef.current.scrollTo({ top: 0 })
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.page])

	const orderChange = React.useCallback((column, orderType, orderCallback) => {
		let order = null
		if (column !== orderColumn.column) {
			order = 'ASC'			
		} else if (column === orderColumn.column && orderColumn.order === 'ASC') {
			order = 'DESC'
		} else {
			order = null
			column = null
		}

		if (orderCallback && typeof(orderCallback) === 'function') {
			setOrderColumn({ column, order, orderType: orderType || 'string', orderServer: true })
			orderCallback(column, order)
		}
		else
			setOrderColumn({ column, order, orderType: orderType || 'string', orderServer: false })

	}, [orderColumn])

	const filterColumn = debounce((text, column) => {		
		filters.filterColumn(text, column)
	}, 1000)

	const selectRow = React.useCallback((row, event) => {
		const index = selectableRows.findIndex(x => x.id === row.id)
		if(index >= 0 && !event.target.checked) {
			setSelectableRows(selectableRows.filter((r) => r.id !== row.id))
		}
		else if (event.target.checked) {
			setSelectableRows([...selectableRows, row])	
		}

		onSelect?.(row, event.target.checked)
	}, [onSelect, selectableRows])

	const selectAllRows = React.useCallback((event) => {
		if(event.target.checked)
			setSelectableRows(originalRows)
		else
			setSelectableRows([])

		onSelectAll?.(event.target.checked)
	}, [onSelectAll, originalRows])

	const onChangeColumn = React.useCallback((event, index) => {
		// let changeColumn = allColumns		
		// changeColumn[index].hidden = !changeColumn[index].hidden
		
		// setAllColumns(changeColumn)
		
		const value = event.target.value
		setAllColumns(value)
	}, [])

	const SearchPeriod = React.useMemo(() => {
		const changeStart = (value) => {
			setFilterDate({...filterDate, startDate: value })
		}
		
		const changeEnd = (value) => {
			setFilterDate({...filterDate, endDate: value })
		}

		return hideSearchPeriod ? null : (
			<SearchPeriodContent>
				<Row>
					{(onGenerateCSV && typeof(onGenerateCSV) === 'function')
					? (
							<OnGenerateCSVButton onClick={onGenerateCSV} title="Exportar para CSV">
								<FontAwesomeIcon icon={faFileCsv} style={{ height: '30px', width: '20px'}}/>
							</OnGenerateCSVButton>							
					)
					: (
						<CSVLink data={selectableRows.length > 0 ? selectableRows : rows} headers={headers} style={{ color: "#8A05BE", padding: "5px", fontSize: '23px' }} title="Exportar para CSV">
							<FontAwesomeIcon icon={faFileCsv} />
						</CSVLink>
					)
					}

					{(onGeneratePDF && typeof(onGeneratePDF) === 'function') && (
						<OnGenerateCSVButton onClick={onGeneratePDF} title="Exportar para PDF">
							<FontAwesomeIcon icon={faFilePdf} style={{ height: '30px', width: '20px'}} />
						</OnGenerateCSVButton>
					)}

					{(onGenerateQRCODE && typeof(onGenerateQRCODE) === 'function') && (
						<OnGenerateCSVButton onClick={onGenerateQRCODE} title="Exportar QRCODE">
							<FontAwesomeIcon icon={faQrcode} style={{ height: '30px', width: '20px'}} />
						</OnGenerateCSVButton>
					)}
				</Row>
			
				<b style={{fontSize: '14px'}}>{dictionary.Start}: </b>
				<RowCalendarDateHour
					type={'date'}
					value={filterDate.startDate}
					label={dictionary.start}
					onChange={changeStart}
					height={'40px'}
					width={'150px'}
					fontSize={'8px'}
				/>
				<b style={{fontSize: '14px'}}>{dictionary.End}: </b>
				<RowCalendarDateHour
					type={'date'}
					value={filterDate.endDate}
					label={dictionary.end}
					onChange={changeEnd}
					height={'40px'}
					width={'150px'}
					fontSize={'8px'}
				/>
				<Button width={'70px'} height={'30px'} onClick={() => filters.filterDate(filterDate.startDate, filterDate.endDate)}>
					<b>{dictionary.update}</b>
				</Button>
			</SearchPeriodContent>
		)
	}, [filterDate, filters, headers, hideSearchPeriod, onGenerateCSV, onGeneratePDF, onGenerateQRCODE, rows, selectableRows])

	const HiddenColumns = React.useMemo(() => (
		<div style={{ display: 'flex'}}>			
			<Select				
				label="Selecionar Colunas"
				multiple
				value={allColumns}
				onChange={onChangeColumn}
				renderValue={(allColumns) => 'Colunas Selecionadas ' + allColumns.length}
				style={{
					border: '1px solid',
					color: PURPLE,
					borderRadius: '5px',
					backgroundColor: '#fff',
				}}
				MenuProps={MenuProps}
			>
				{columns.map((column, index) => (
					<MenuItem key={index} value={column} >
						<ListItemIcon>
							<Checkbox checked={allColumns.indexOf(column) > -1} />
						</ListItemIcon>
						<ListItemText primary={column.Header} />				
					</MenuItem>
				))}
			</Select>
		</div>
	), [allColumns, columns, onChangeColumn])

	const SelectPage = () => (
		<div>
			<label style={{marginRight: '3px'}}>Página</label>
			<select onChange={(event) => filters.selectPage(event.target.value)} defaultValue={pagination.page}>
				{[...Array(pagination.totalPages)].map((x, index) => (
					<option key={index}>{index + 1}</option>
				))}
			</select>
		</div>
	)
	
	return (
		<ModalContainer>
			<Container>
				<ContentInfo>
					<div style={{ display: 'flex'}}>
						{(onChangeView && typeof(onChangeView) === 'function') && (
							<>
								<Title isSelected={view && view === 'table'} onClick={() => onChangeView('table')}>{name}</Title>
								<Title isSelected={view && view === 'register'} onClick={() => onChangeView('register')}>{registerTitle}</Title>
							</>
						)}
						{(!onChangeView) && (
							<Title>{name}</Title>
						)}
					</div>
					<div style={{ display: 'flex'}}>
						{showHiddenColumn ? HiddenColumns : null}
						{SearchPeriod}
					</div>
				</ContentInfo>
			</Container>
			<Container ref={tableRef}>
				<Table>
					<TableHeaderContent>
						<THeadRow>
							{selectable && (
								<TableHeader width={'auto'} style={{ maxWidth: '30px'}}>	
									<input type="checkbox" checked={selectAll} title="Selecionar linha" onChange={selectAllRows} />								
								</TableHeader>
							)}
							{columns.map((column, index) => {
								if((column.showColumn || column.showColumn == null) && allColumns.indexOf(column) > -1) {
									if(column.fixed) {
										var left = headerLeft
										headerLeft += parseInt(column.width)
										return (				
											<TableHeaderFixed key={index} index={index} left={left} 												
												width={column.width} 												
											>
												<Column>
													<EmptyContent
														style={{cursor: column.order ? 'pointer' : 'default'}} 
														onClick={() => {
															if (column.order && !column.orderServer)
																orderChange(column.accessor, column.orderType, column.orderCallback)
														}} 
													>
														{column.accessor === orderColumn.column && (
															<>
																{orderColumn.order === 'ASC' && (
																	<FontAwesomeIcon style={{fontSize: '10px'}} icon={faArrowDown} />
																)}
																{orderColumn.order === 'DESC' && (
																	<FontAwesomeIcon style={{fontSize: '10px'}} icon={faArrowUp} />
																)}
															</>
														)}
														{column.Header}	
													</EmptyContent>											
													<InputContent>
														{(column.showFilter && (!column.filterType || column.filterType === 'string')) && (
															<InputSearch 
																onChange={(e) => {
																	filterColumn(e.target.value, column.accessor)
																}} 
																placeholder={`${pagination.total} resultados`} 
																defaultValue={
																	(!isUndefinedOrNull(filters.searchFilters) && 
																	!isUndefinedOrNull(filters.searchFilters[column.accessor]) )
																	? filters.searchFilters[column.accessor] : ''}/>															
														)}
														{(column.showFilter && column.filterType === 'dateYear') && (
															<SelectDate 
																onChange={(e) => {
																	setYear(e.target.value)
																	filterColumn(e.target.value, column.accessor)																	
																}}
																value={year}
															>
																{years.map(year => (
																	<OptionDate key={year} value={year}>{year}</OptionDate>
																))}
																<OptionDate value={''}>Todos</OptionDate>
															</SelectDate>
														)}
														{(
															column.showFilter && column.filterType === 'yesOrNo' && (
																<SelectDate
																	onChange={(e) => {
																		setYear(e.target.value)
																		filterColumn(e.target.value, column.accessor)																	
																	}}
																	value={
																	(!isUndefinedOrNull(filters.searchFilters) && 
																	!isUndefinedOrNull(filters.searchFilters[column.accessor]) )
																	? filters.searchFilters[column.accessor] : ''}
																>
																	<OptionDate value={''}>Todos</OptionDate>
																	<OptionDate value={false}>Sim</OptionDate>
																	<OptionDate value={true}>Não</OptionDate>
																</SelectDate>
															)
														)}
													</InputContent>
												</Column>														
											</TableHeaderFixed>	
										)
									}
									else 
										return(											
											<TableHeader key={index} width={column.width}>
												<Column>
													<EmptyContent
														style={{cursor: column.order ? 'pointer' : 'default'}} 
														onClick={() => {
															if (column.order)
																orderChange(column.accessor, column.orderType, column.orderCallback)
														}}>
														{column.accessor === orderColumn.column && (
															<>
																{orderColumn.order === 'ASC' && (
																	<FontAwesomeIcon style={{fontSize: '10px'}} icon={faArrowDown} />
																)}
																{orderColumn.order === 'DESC' && (
																	<FontAwesomeIcon style={{fontSize: '10px'}} icon={faArrowUp} />
																)}
															</>
														)}
														{column.Header}
													</EmptyContent>												
													{(column.showFilter && (!column.filterType || column.filterType === 'string')) && (
														<InputSearch 
															onChange={(e) => {
																filterColumn(e.target.value, column.accessor)
															}} 
															placeholder={`${pagination.total} resultados`} 
															defaultValue={
																	(!isUndefinedOrNull(filters.searchFilters) && 
																	!isUndefinedOrNull(filters.searchFilters[column.accessor]) )
																	? filters.searchFilters[column.accessor] : ''}/>															
													)}
													{(column.showFilter && column.filterType === 'dateYear') && (
														<SelectDate 
															onChange={(e) => {
																setYear(e.target.value)
																filterColumn(e.target.value, column.accessor)																	
															}}
															value={year}
														>
															{years.map(year => (
																<OptionDate key={year} value={year}>{year}</OptionDate>
															))}
															<OptionDate value={''}>Todos</OptionDate>
														</SelectDate>
													)}
													{(
														column.showFilter && column.filterType === 'yesOrNo' && (
															<SelectDate
																onChange={(e) => {
																	setYear(e.target.value)
																	filterColumn(e.target.value, column.accessor)																	
																}}
																value={
																	(!isUndefinedOrNull(filters.searchFilters) && 
																	!isUndefinedOrNull(filters.searchFilters[column.accessor]) )
																	? filters.searchFilters[column.accessor] : ''}
															>
																<OptionDate value={''}>Todos</OptionDate>
																<OptionDate value={false}>Sim</OptionDate>
																<OptionDate value={true}>Não</OptionDate>
															</SelectDate>
														)
													)}
												</Column>
											</TableHeader>
										)
								} else {
									return null
								}
							})}
							{(actions && Array.isArray(actions)) ? (<TableHeader>Ações</TableHeader>) : 
							(onEdit && typeof(onEdit) === 'function') | (onDelete && typeof(onDelete) === 'function') ? (
								<TableHeader>Ações</TableHeader>
							) : null}
						</THeadRow>
					</TableHeaderContent>					
					<TableBody>
						{originalRows.map((row, index) => {
							let leftWidth = 0			
							return(
								<TableRow key={index} index={index}>
									{selectable && (
										<TableCell width={'auto'} style={{ maxWidth: '30px'}}>
											<input 
												type="checkbox" 
												checked={													
													(!selectAll && selectedIds?.includes(row.id)) ||
													(selectAll && !deselectIds?.includes(row.id))} 
												title="Selecionar linha" 
												onChange={(e => { selectRow(row, e) })} />						
										</TableCell>
									)}
									{columns.map((column, columnIndex) => {
										if((column.showColumn || column.showColumn == null) && allColumns.indexOf(column) > -1) {
											if(!column.fixed)
												return (
													<TableCell key={columnIndex} style={
														column.getStyle && typeof(column.getStyle) === 'function' 
														? column.getStyle(row[column.accessor]) 
														: column.style} width={column.width}>
														{column.valueFormatter && typeof(column.valueFormatter) === 'function' 
															? column.valueFormatter(row, row[column.accessor]) 
															: row[column.accessor]}
													</TableCell>
												)
											else {
												var left = leftWidth
												leftWidth += parseInt(column.width)
												return (
													<TableCellFixed 
														style={
														column.getStyle && typeof(column.getStyle) === 'function' 
														? column.getStyle(row) 
														: column.style}
														key={columnIndex}
														index={index} 
														width={column.width} 
														left={left}
														columnIndex={columnIndex}>
														{column.valueFormatter && typeof(column.valueFormatter) === 'function'
															? column.valueFormatter(row, row[column.accessor]) 
															: row[column.accessor]}
													</TableCellFixed>
												)
											}
										} else {
											return null
										}
									},									
									)}
									{(actions && Array.isArray(actions)) ? (
										<TableCell width='170px'>
											{actions.map((e, index) => (
												<>{e.view === undefined | e.view ? (
													<OnEditButton key={index} style={e.style} title={e.title} onClick={() => e.action(row)} >{e.label}</OnEditButton>
												) : null} </>
											))}
										</TableCell>
									) :
									(onEdit || onDelete) && (
										<TableCell>
											{onEdit && typeof(onEdit) === 'function' && (
												<OnEditButton onClick={() => onEdit(row)}>{onEditTitle}</OnEditButton>
											)}
											{onDelete && typeof(onDelete) === 'function' && (
												<OnEditButton onClick={() => onDelete(row)} >{onDeleteTitle}</OnEditButton>
											)}
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>					
				</Table>
			</Container>
			{(originalRows.length !== 0 && !loading) && (
				<ContentInfo>
					<PaginationButton onClick={filters.firstPage} disabled={pagination.page === 1}>
						<FontAwesomeIcon icon={faAngleDoubleLeft} />
					</PaginationButton>
					<PaginationButton onClick={filters.previous} disabled={pagination.page === 1}>
						<FontAwesomeIcon icon={faAngleLeft} />
					</PaginationButton>
				Página {pagination.page} de {pagination.totalPages}
					<PaginationButton onClick={filters.next} disabled={pagination.page === pagination.totalPages}>
						<FontAwesomeIcon icon={faAngleRight} />
					</PaginationButton>
					<PaginationButton onClick={filters.lastPage} disabled={pagination.page === pagination.totalPages}>
						<FontAwesomeIcon icon={faAngleDoubleRight} />
					</PaginationButton>
				|
					<p>{totalSelected} de linhas selecionadas</p>

					<SelectPage />
				</ContentInfo>
			)}
			{(originalRows.length === 0 && !loading) && (
				<EmptyTable>
					<EmptyContent>
						<FontAwesomeIcon style={{width: 130, height: 130}} icon={faInbox} />		
					</EmptyContent>
					<EmptyContent>
						<EmptyLabel>Não foram encontrados registros.</EmptyLabel>	
					</EmptyContent>
				</EmptyTable>
			)}
		</ModalContainer>
	)
}

export default TableReport