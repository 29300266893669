import {
	GRAY_MEDIUM3,
	PURPLE,
	PURPLE_DARK,
	PURPLE_LIGHT,
	PURPLE_MEDIUM,
	WHITE,
	YELLOW_LIGHT,
} from 'src/styles/colors'
import {Button} from 'src/styles/globalStyles'
import styled from 'styled-components'

export const ModalContainer = styled.div`
	width: calc(100% - 80px);
	min-width: 300px;
	height: calc(100% - 120px);
	min-height: 400px;
	margin: 40px;
`

export const Container = styled.div`
	position: relative;
	max-width: 100%;
	max-height: calc(100% - 80px);
	overflow: hidden;
	border-radius: 5px;
	overflow: auto;
`

export const ContentInfo = styled.div`
	display: flex;
	justify-content: space-between;
	/* height: 40px; */
	align-items: center;
	flex-wrap: wrap;
`
export const SearchPeriodContent = styled(ContentInfo)`
	justify-content: center;
	background-image: linear-gradient(to top, ${PURPLE_LIGHT}, #fff);
	color: ${PURPLE};
	padding: 0px 5px;
	border-radius: 10px;
	margin-right: 10px;
`

export const Title = styled.div`
	cursor: pointer;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	line-height: 48px;
	color: ${(props) => (props.isSelected ? `${WHITE}` : `${PURPLE}`)};

	background-image: ${(props) =>
		props.isSelected
			? `linear-gradient(to top, ${PURPLE}, ${PURPLE})`
			: `linear-gradient(to top, ${PURPLE_LIGHT}, #fff)`};

	border-radius: 10px 10px 0px 0px;
	box-shadow: -0px -4px 1em #776e6e;

	z-index: ${(props) => (props.isSelected ? 2 : 1)};

	padding: 0px 10px;
	width: fit-content;

	&:hover {
		color: #fff !important;
		background-image: linear-gradient(to top, ${PURPLE}, ${PURPLE});
	}
`
export const Table = styled.table`
	white-space: nowrap;
	border-radius: 5px;
	border: none;
	border-collapse: separate;
	border-spacing: 1px;
	border-color: #fff;
	table-layout: fixed;
	min-width: 100%;
	height: 100%;
	max-height: 100%;
	background-color: ${PURPLE_MEDIUM};
`
export const TableHeaderContent = styled.thead``

export const THeadRow = styled.tr``

export const TableHeader = styled.th`
	height: 100%;
	width: ${(props) => (props.width ? props.width : '150px')};
	max-width: ${(props) => (props.width ? props.width : '150px')};
	min-width: ${(props) => (props.width ? props.width : '150px')};
	background-image: linear-gradient(to top, ${PURPLE_LIGHT}, #fff);
	font-size: 15px;
	line-height: 20px;
	color: ${PURPLE};
	text-align: center;
	z-index: 150;
	position: sticky;
	top: 0;
	word-wrap: break-word;
	padding: 4px;
`
export const TableHeaderFixed = styled(TableHeader)`
	background-color: ${PURPLE_LIGHT};
	z-index: 250 !important;
	width: ${(props) => (props.width ? props.width : '150px')};
	max-width: ${(props) => (props.width ? props.width : '150px')};
	min-width: ${(props) => (props.width ? props.width : '150px')};
	left: ${(props) => props.left.toString().concat('px')};
	display: table-cell;
`

export const InputContent = styled.div`
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: 0;
	padding-bottom: 1px;
`

export const InputSearch = styled.input`
	border-radius: 10px;
	padding: 3px;
	border: 1px solid ${PURPLE};
	width: 70%;
	text-align: center;
	height: auto;
	max-height: 14px;
`

export const TableRow = styled.tr`
	background-color: ${(props) =>
		props.index % 2 === 0 ? GRAY_MEDIUM3 : '#FFF'};
	&:hover {
		background-color: ${YELLOW_LIGHT};
	}
	min-height: 35px;
	max-height: 35px;
	height: 35px;
`

export const TableCell = styled.td`
	padding: 5px;
	width: ${(props) => (props.width ? props.width : '150px')};
	text-align: center;
	position: relative;
	word-wrap: break-word;
	white-space: initial;
	min-width: ${(props) => (props.width != null ? props.width : '150px')};
	max-width: ${(props) => (props.width != null ? props.width : '150px')};
	height: auto;
`

export const TableCellFixed = styled(TableCell)`
	position: sticky;
	width: ${(props) => (props.width != null ? props.width : '150px')};
	min-width: ${(props) => (props.width != null ? props.width : '150px')};
	max-width: ${(props) => (props.width ? props.width : '150px')};
	left: ${(props) => props.left.toString().concat('px')};
	z-index: 100 !important;
	background-color: ${(props) =>
		props.index % 2 === 0 ? GRAY_MEDIUM3 : '#FFF'};
`
export const TableBody = styled.tbody`
	position: relative;
`

export const PaginationButton = styled(Button)`
	width: 100px;
	height: 35px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	border: none;
	color: #333;
	outline: none;
	margin: 3px 0px;
	background-color: ${PURPLE_LIGHT};
`
export const EmptyTable = styled.div`
	display: grid;
	min-height: 250px;
	background-color: ${PURPLE_MEDIUM};
`

export const EmptyContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 3px;
	min-height: 70px;
`
export const EmptyLabel = styled.label`
	text-align: center;
	font-size: 20px;
`

export const OnEditButton = styled(Button)`
	width: auto;
	height: auto;
	margin-right: 4px;
	margin-top: 2px;
`
export const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	width: 100%;
`
export const OrderButton = styled(Button)`
	background-color: ${PURPLE_LIGHT};
	border: 0;
	margin: 0;
	width: auto;
	height: auto;
`
export const InputDate = styled(InputSearch).attrs({
	type: 'month',
})`
	::-webkit-calendar-picker-indicator {
		margin-left: -25px;
	}
	text-align: left;
`
export const OnGenerateCSVButton = styled.button`
	border: none;
	/* padding: 10px; */
	height: 37px;
	width: 30px;
	color: ${PURPLE};
	background-image: linear-gradient(to top, #ebebea, #fff);
	cursor: pointer;
	:hover {
		color: ${PURPLE_DARK};
	}
`

export const Row = styled.div`
	display: flex;
	flex-direction: 'row';
`

const ITEM_HEIGHT = 30
const ITEM_PADDING_TOP = 8

export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 400,
			color: PURPLE,
		},
		labelStyle: {
			color: PURPLE,
		},
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'left',
	},
	variant: 'menu',
}

export const SelectDate = styled.select`
	background-color: #fdfdfd;
	color: ${PURPLE};
	border-radius: 10px;
	border: 1px solid ${PURPLE};
	width: 100%;
	text-align: center;
	height: auto;
	max-height: 20px;
`
export const OptionDate = styled.option`
	padding: 4px;
	color: ${PURPLE}
	border-radius: 8px;	
`
