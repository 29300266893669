import { CardContainer, CardDescription, CardIcon, CardTitle, CardTitleContainer } from "./styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Skeleton from "src/components/Skeleton"

const IndicatorCard = ({ title, value, loading, icon, isCustomIcon }) => {
	return (
		<CardContainer backgroundColor={"linear-gradient(45deg,#f6f6f6,#e9e9e9)"}>
			<CardTitleContainer>
				<CardIcon>
					{isCustomIcon ? icon : <FontAwesomeIcon size={30} icon={icon} />}
				</CardIcon>
				<div>
					<CardTitle>{title}</CardTitle>
					<CardDescription>{loading ? <Skeleton width="80px" /> : value}</CardDescription>
				</div>
			</CardTitleContainer>
		</CardContainer>
	)
}

export default IndicatorCard