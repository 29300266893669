import { CardContent, Collapse, Typography } from '@material-ui/core'
import { Container, Content, HeaderRow, Item, ItemTitle, Title } from './styles'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React from 'react'
import Skeleton from '../Skeleton'
import { fetchData } from 'src/hooks/useFetch'

const ListFifoBatteries = ({ nextFifoBatteries }) => {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)
	const [selectedItem, setSelectedItem] = React.useState(0)

	React.useEffect(() => {
		const loadData = async () => {
			try {
				const response = await fetchData('report/nextFifoBatteries')
				setData(response)
				setLoading(false)
			} catch {
				setLoading(false)
			}
		}

		loadData()
	}, [])

	const onChangeItem = React.useCallback((index) => {
		console.log(index)
		setSelectedItem(prev => prev === index ? -1 : index)
	}, [])

	return (
		<Container>
			{loading ? (
				<Skeleton width='90%' height='340px' />
			) : (
				<Content>
					{Object.keys(data).map((item, index) => (
						<Item key={item}>
							<HeaderRow onClick={() => { onChangeItem(index) }}>
								<ItemTitle>{item}</ItemTitle>
								<FontAwesomeIcon icon={selectedItem === index ? faArrowUp : faArrowDown} />
							</HeaderRow>
							<Collapse in={selectedItem === index} timeout="auto" unmountOnExit>
								<CardContent>
									{data[item]?.batteries.map(e => (
										<Typography>{e.battery.name}</Typography>
									))}
								</CardContent>
							</Collapse>
						</Item>
					))}
				</Content>
			)}
		</Container>
	)
}

export default ListFifoBatteries
