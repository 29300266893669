import {PURPLE_DARK} from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 10px;
`

export const Content = styled.div`
	margin: 10px;
`

export const CardsContainer = styled.div`
	background-color: rgb(255, 255, 255);
	padding: 10px;
	border-radius: 8px;
	width: ${(props) => props.width};
	display: flex;
	flex-direction: column;
	@media (max-width: 1390px) {
		width: 100% !important;
	}
`

export const CardContent = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: space-around;
`

export const CardTitle = styled.h2`
	font-weight: 400;
	font-size: 18px;
	color: ${PURPLE_DARK};
	padding: 0px 15px 15px 0px;
	border-bottom: 1px solid ${PURPLE_DARK};
`
